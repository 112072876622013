import Select from '@standby/common-ui/components/atoms/Input/Select/Select';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import { observer } from 'mobx-react';

import st from './style.module.scss';

const SelectRow = observer(({ type = '', label = '', value, onChange, options = [], requiredGuideMessage = '' }) => (
  <div className={st.row}>
    <div className={st.category}>{label}</div>
    <Select
      className={st.input}
      value={value}
      onChange={onChange}
      name={type}
      errorText={
        <Error
          name={type}
          value={value}
          errorCase={{
            required: requiredGuideMessage,
          }}
        />
      }
    >
      <option value="">-</option>
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.value}
        </option>
      ))}
    </Select>
  </div>
));

export default SelectRow;
