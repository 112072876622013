import { addCommas } from '@common/module/replaceNumber';
import listSt from '@commonComponents/CheckResultList/CheckResultList.module.scss';
import DragFileUpload from '@commonComponents/DragFileUpload/DragFileUpload';
import MessageHint from '@commonComponents/MessageHint/MessageHint';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import DatePicker from '@standby/common-ui/components/molecules/DatePicker/DatePicker';
import clsx from 'clsx';
import { useObserver } from 'mobx-react';
import { useEffect } from 'react';

import st from '../Process.module.scss';
import vm from './NationalLocalTaxSendVm';

function NationalLocalTaxSend(props) {
  useEffect(() => {
    vm.load(props.businessId, props.year);
  }, [vm.instalmentYn]);

  return useObserver(() =>
    vm.taxInfo ? (
      <>
        <li>
          <div className={listSt.rowTitle}>서류 업로드</div>
          <div className={listSt.rowContent}>
            {vm.instalmentYn === true && (
              <div className={st.fileList}>
                <div className={st.fileWrap}>
                  <div className={st.title}>
                    국세 1차 납부서 <span>(필수)</span>
                  </div>
                  <DragFileUpload
                    multiple
                    uploadFile={vm.taxInfo.nationalTax_1st}
                    changeUploadFile={vm.changeNationalTax_1st}
                    uploadBtnText={
                      <>
                        <strong>국세 1차</strong> 납부서 업로드
                      </>
                    }
                  />
                </div>
                <div className={st.fileWrap}>
                  <div className={st.title}>
                    국세 2차 납부서 <span>(필수)</span>
                  </div>
                  <DragFileUpload
                    multiple
                    uploadFile={vm.taxInfo.nationalTax_2nd}
                    changeUploadFile={vm.changeNationalTax_2nd}
                    uploadBtnText={
                      <>
                        <strong>국세 2차</strong> 납부서 업로드
                      </>
                    }
                  />
                </div>
              </div>
            )}
            <div className={st.fileList}>
              {vm.instalmentYn === false && (
                <div className={st.fileWrap}>
                  <div className={st.title}>
                    국세 납부서 <span>(세액 있는 경우만)</span>
                  </div>
                  <DragFileUpload
                    multiple
                    uploadFile={vm.taxInfo.nationalTaxFile}
                    changeUploadFile={vm.changeNationalTaxFile}
                    uploadBtnText={
                      <>
                        <strong>국세</strong> 납부서 업로드
                      </>
                    }
                  />
                </div>
              )}
              <div className={st.fileWrap}>
                <div className={st.title}>
                  지방세 납부서 <span>{vm.instalmentYn === false ? '(세액 있는 경우만)' : '(필수)'}</span>
                </div>
                <DragFileUpload
                  multiple
                  uploadFile={vm.taxInfo.localTaxFile}
                  changeUploadFile={vm.changeLocalTaxFile}
                  uploadBtnText={
                    <>
                      <strong>지방세</strong> 납부서 업로드
                    </>
                  }
                />
              </div>
            </div>
            <div className={st.fileList}>
              <div className={st.fileWrap}>
                <div className={st.title}>
                  세무조정계산서 <span>(필수)</span>
                </div>
                <DragFileUpload
                  fileSzie={104857600}
                  multiple
                  uploadFile={vm.taxInfo.statementOfTaxAdjustment}
                  changeUploadFile={vm.changeStatementOfTaxAdjustment}
                  uploadBtnText={
                    <>
                      <strong>세무조정계산서</strong> 업로드
                    </>
                  }
                  allowFileType="PDF"
                />
              </div>
              <div className={st.fileWrap}>
                <div className={st.title}>
                  재무제표확인원 <span>(선택)</span>
                </div>
                <DragFileUpload
                  uploadFile={vm.taxInfo.financialStatementsConfirmation}
                  changeUploadFile={vm.changeFinancialStatementsConfirmation}
                  uploadBtnText={
                    <>
                      <strong>재무제표확인원</strong> 업로드
                    </>
                  }
                  allowFileType="PDF"
                />
              </div>
            </div>
            <div className={st.fileList}>
              <div className={st.fileWrap}>
                <div className={st.title}>
                  법인세 관련 기타서류 <span>(선택)</span>
                </div>
                <DragFileUpload
                  multiple
                  uploadFile={vm.taxInfo.etcFile}
                  changeUploadFile={vm.changeEtcFile}
                  uploadBtnText={
                    <>
                      <strong>기타서류</strong> 업로드
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className={listSt.rowTitle} style={{ position: 'relative', top: '8px' }}>
            국세
          </div>
          <div className={clsx(listSt.rowContent, st.inputList)}>
            <div className={st.row}>
              <div className={st.title}>
                총 결정세액<span className={st.requiredMark}>*</span>
              </div>
              <div className={st.inputs}>
                <InputText
                  name=""
                  value={addCommas(vm.taxInfo.nationalTax.finalTaxLiability)}
                  onChange={vm.finalTaxLiability}
                  style={{ textAlign: 'right' }}
                />
                <span className={st.inputSubText}>원</span>
              </div>
            </div>
            <div className={st.row}>
              <div className={clsx(st.title, st.minus)}>기 납부세액</div>
              <div className={st.inputs}>
                <InputText
                  name=""
                  value={addCommas(vm.taxInfo.nationalTax.prePaidTax)}
                  onChange={vm.prePaidTax}
                  style={{ textAlign: 'right' }}
                />
                <span className={st.inputSubText}>원</span>
              </div>
            </div>
            <div className={st.row}>
              <div className={clsx(st.title, st.plus)}>추가 납부세액</div>
              <div className={st.inputs}>
                <InputText
                  name=""
                  value={addCommas(vm.taxInfo.nationalTax.additionalTaxPayable)}
                  onChange={vm.additionalTaxPayable}
                  style={{ textAlign: 'right' }}
                />
                <span className={st.inputSubText}>원</span>
              </div>
            </div>
            <div className={st.row}>
              <div className={clsx(st.title, st.plus)}>농특세</div>
              <div className={st.inputs}>
                <InputText
                  name=""
                  value={addCommas(vm.taxInfo.nationalTax.specialTaxForRuralDevelopment)}
                  onChange={vm.specialTaxForRuralDevelopment}
                  style={{ textAlign: 'right' }}
                />
                <span className={st.inputSubText}>원</span>
              </div>
            </div>
            <div className={st.row}>
              <div className={clsx(st.title, st.equal)}>차가감 납부세액</div>
              <div className={st.inputs}>
                <div style={{ textAlign: 'right' }} className={st.inputSizeView}>
                  {addCommas(vm.total)}
                </div>
                <span className={st.inputSubText}>원</span>
              </div>
            </div>
            {vm.instalmentYn === true && (
              <>
                <div className={clsx(st.row, st.inputListBorderTop)}>
                  <div className={st.title}>
                    1차 납부할 세액<span className={st.requiredMark}>*</span>
                  </div>
                  <div className={st.inputs}>
                    <InputText
                      name=""
                      value={addCommas(vm.taxInfo.nationalTax.taxPayable_1st)}
                      onChange={vm.taxPayable_1st}
                      style={{ textAlign: 'right' }}
                    />
                    <span className={st.inputSubText}>원</span>
                  </div>
                </div>
                <div className={st.row}>
                  <div className={st.title}>
                    1차 납부기한<span className={st.requiredMark}>*</span>
                  </div>
                  <div className={st.inputs}>
                    <DatePicker
                      className={st.datePicker}
                      name=""
                      selected={vm.taxInfo.nationalTax.dueDateForPayment_1st}
                      onChange={vm.dueDateForPayment_1st}
                    />
                  </div>
                </div>
                <div className={st.row}>
                  <div className={st.title}>
                    2차 납부할 세액<span className={st.requiredMark}>*</span>
                  </div>
                  <div className={st.inputs}>
                    <InputText
                      name=""
                      value={addCommas(vm.taxInfo.nationalTax.taxPayable_2nd)}
                      onChange={vm.taxPayable_2nd}
                      style={{ textAlign: 'right' }}
                    />
                    <span className={st.inputSubText}>원</span>
                  </div>
                </div>
                <div className={st.row}>
                  <div className={st.title}>
                    2차 납부기한<span className={st.requiredMark}>*</span>
                  </div>
                  <div className={st.inputs}>
                    <DatePicker
                      className={st.datePicker}
                      name=""
                      selected={vm.taxInfo.nationalTax.dueDateForPayment_2nd}
                      onChange={vm.dueDateForPayment_2nd}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </li>
        <li>
          <div className={listSt.rowTitle} style={{ position: 'relative', top: '7px' }}>
            지방세<span className={st.requiredMark}>*</span>
          </div>
          <div className={listSt.rowContent}>
            <div className={st.inputs}>
              <InputText
                name=""
                value={addCommas(vm.taxInfo.localTax.taxAmount)}
                onChange={vm.taxAmount}
                style={{ textAlign: 'right' }}
              />
              <span className={st.inputSubText}>원</span>
            </div>
          </div>
        </li>
        {vm.instalmentYn === false && (
          <li>
            <div className={listSt.rowTitle} style={{ top: '7px' }}>
              국세 납부기한<span className={st.requiredMark}>*</span>
            </div>
            <div className={listSt.rowContent}>
              <DatePicker
                className={st.datePicker}
                name=""
                selected={vm.taxInfo.nationalTax.dueDateForPayment}
                onChange={vm.nationalTaxDueDateForPayment}
              />
            </div>
          </li>
        )}
        <li>
          <div className={listSt.rowTitle} style={{ top: '7px' }}>
            지방세 납부기한<span className={st.requiredMark}>*</span>
          </div>
          <div className={listSt.rowContent}>
            <DatePicker
              className={st.datePicker}
              name=""
              selected={vm.taxInfo.localTax.dueDateForPayment}
              onChange={vm.localTaxDueDateForPayment}
            />
          </div>
        </li>
        <li className={listSt.hasBtn}>
          <div className={listSt.rowTitle}>
            알림톡 받으실 분<span className={st.requiredMark}>*</span>
          </div>
          <div className={listSt.rowContent} style={{ marginTop: '2px' }}>
            <ul className={st.checkList}>
              {vm.taxInfo.alimTalkToList.map((person, index) => (
                <li key={index}>
                  <CheckBox checked={person.checkedYn} onChange={(e) => vm.alimTalkToList(e, index)}>
                    {person.position ? `${person.position} : ` : ''} {person.name}님
                  </CheckBox>
                </li>
              ))}
            </ul>
          </div>
        </li>
        <li className={listSt.hasBtn}>
          <div>
            <button
              style={{ width: '576px' }}
              type="button"
              disabled={!vm.activeCompletedNotice}
              className={listSt.sendBtn}
              onClick={() => vm.sendCompletedNotice(props.businessId)}
            >
              <span>저장 + 법인세 신고완료 알림톡 발송</span>
            </button>
            <MessageHint text="알림톡 메세지보기" style={{ width: '284px', display: 'inline-block' }}>
              <img src="/images/talkPreview_법인세신고완료.png?1" />
            </MessageHint>
          </div>
          <div className={listSt.saveFixBtn} style={{ bottom: '0' }}>
            <div className={listSt.btn}>
              <button
                type="button"
                disabled={!vm.activeCompletedNotice}
                onClick={() => vm.saveCompletedNotice(props.businessId)}
              >
                수정사항 저장
              </button>
            </div>
            <div className={clsx(listSt.ex, !vm.activeCompletedNotice ? listSt.disabled : null)}>
              저장시 고객화면 반영
            </div>
          </div>
        </li>
      </>
    ) : (
      []
    ),
  );
}

export default NationalLocalTaxSend;
