import PageNav from '@commonComponents/PageNav/PageNav';
import { registrationStatusTypes } from '@pages/StaffList/constant';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useSearchParams } from 'react-router-dom';

import PendingApprovalStaffTable from './components/PendingApprovalStaffTable';
import WorkingStaffTable from './components/WorkingStaffTable';
import { useEmployeeListQuery } from './queries';
import st from './style.module.scss';

const StaffList = observer(() => {
  const [searchParams, setSearchParams] = useSearchParams();

  const registrationStatusType = registrationStatusTypes[searchParams.get('type')] || registrationStatusTypes.재직중;

  const { data, isSuccess } = useEmployeeListQuery(registrationStatusType);

  const setRegistrationStatusType = (type) => {
    searchParams.set('type', registrationStatusTypes[type] || registrationStatusTypes.재직중);
    setSearchParams(searchParams);
  };

  const employees = isSuccess && data ? data.employees : [];

  return (
    <>
      <PageNav nav={['권한관리', '직원목록']} />
      <div className={st.buttonGroup}>
        {Object.values(registrationStatusTypes).map((type) => (
          <SquareBtn
            key={type}
            className={clsx(st.button, registrationStatusType !== type && st.inactive)}
            onClick={() => setRegistrationStatusType(type)}
          >
            {type}
          </SquareBtn>
        ))}
      </div>
      {registrationStatusType === registrationStatusTypes.재직중 && <WorkingStaffTable employees={employees} />}
      {registrationStatusType === registrationStatusTypes.가입승인대기 && (
        <PendingApprovalStaffTable employees={employees} />
      )}
    </>
  );
});

export default StaffList;
