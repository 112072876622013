import BusinessHomeModel from '@model/BusinessHomeModel';
import BusinessHomeService from '@service/BusinessHomeService';
import { observable, toJS } from 'mobx';
import { format, isValid, addDays } from 'date-fns';

class BookKeepingServicePauseRequestModalVm {
  constructor() {
    this.data = observable({
      alimtalkToList: toJS(BusinessHomeModel.business.customer.alimtalkToList.BOOKKEEPING_SERVICE_PAUSE_REQUEST),
      pauseStartedAt: '',
    });
  }
  onChangePauseStartedAt(e) {
    let date = '';

    if (e) {
      const year = e.getFullYear();
      const month = ('0' + (e.getMonth() + 1)).slice(-2);
      const day = ('0' + e.getDate()).slice(-2);

      date = year + '-' + month + '-' + day;
    }

    this.data.pauseStartedAt = date;
  }
  changeAlimtalkToList(e, index) {
    this.data.alimtalkToList[index].checkedYn = e.target.checked;
  }
  isSaveBtnActive(type) {
    if (!this.data.pauseStartedAt) {
      return false;
    }
    if (type === 'alimtalk') {
      return this.data.alimtalkToList.some((person) => {
        //알림톡 받으실분
        return person.checkedYn === true;
      });
    }

    return true;
  }
  request(businessId, type) {
    const param = {
      pauseStartedAt: this.data.pauseStartedAt,
    };

    if (type === 'alimtalk') {
      param.userIds = this.data.alimtalkToList
        .filter((person) => person.checkedYn)
        .map((checkedPerson) => checkedPerson.id);
    }

    BusinessHomeService.bookKeepingServicePause(businessId, param, type);
  }
  get pauseStartedAtMinDate() {
    const freeBookkeepingServiceEndAt =
      BusinessHomeModel?.business?.customer?.bookkeepingService?.freeBookkeepingServiceEndAt;

    // 오늘 날짜 객체 생성
    const today = new Date();

    // 오늘 날짜의 다음 날 생성
    const tomorrow = addDays(today, 1);

    // freeBookkeepingServiceEndAt가 undefined거나 유효한 날짜가 아닌 경우 tomorrow 반환
    if (!freeBookkeepingServiceEndAt || !isValid(new Date(freeBookkeepingServiceEndAt))) {
      return format(tomorrow, 'yyyy-MM-dd'); // "yyyy-MM-dd" 형식의 날짜 문자열 반환
    }

    // freeBookkeepingServiceEndAt에 하루를 더하기
    const freeBookkeepingServiceEndAtTomorrow = addDays(new Date(freeBookkeepingServiceEndAt), 1);

    // 미래 날짜 비교 (tomorrow와 freeBookkeepingServiceEndAtTomorrow 중 더 미래인 날짜 반환)
    return freeBookkeepingServiceEndAtTomorrow > tomorrow
      ? format(freeBookkeepingServiceEndAtTomorrow, 'yyyy-MM-dd')
      : format(tomorrow, 'yyyy-MM-dd');
  }
}

export default BookKeepingServicePauseRequestModalVm;
