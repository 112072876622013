import { useObserver } from 'mobx-react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import ModalEditView from './component/ModalEditView';
import vm from './ModalEditVm';

function ModalEdit(props) {
  const location = useLocation();
  const businessId = queryString.parse(location.search).businessId;

  return useObserver(() => (
    <ModalEditView {...props} onSubmit={(e) => vm.submit(e, props.type, businessId, props.inputType, props?.parent)} />
  ));
}

export default ModalEdit;
