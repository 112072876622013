import portalModel from '@model/portalModel';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';

import st from '../../style.module.scss';
import EditStaffModal from '../EditStaffModal';
import WithdrawStaffModal from '../WithdrawStaffModal';

const WorkingStaffTable = observer(({ employees }) => {
  const openEditModal = (employeeId) => {
    runInAction(() => {
      portalModel.title = '직원 관리';
      portalModel.content = <EditStaffModal employeeId={employeeId} />;
    });
  };
  const openWithdrawModal = (employeeId) => {
    runInAction(() => {
      portalModel.title = '퇴사처리';
      portalModel.content = <WithdrawStaffModal employeeId={employeeId} />;
    });
  };

  return (
    <table className={st.staffList}>
      <colgroup>
        <col width="10%" />
        <col width="8%" />
        <col width="16%" />
        <col width="12%" />
        <col />
        <col width="12%" />
        <col width="15%" />
      </colgroup>
      <thead>
        <tr>
          <th>이름</th>
          <th>직위</th>
          <th>이메일</th>
          <th>최고관리자여부</th>
          <th>조직</th>
          <th>업데이트 일시</th>
          <th>관리</th>
        </tr>
      </thead>
      <tbody>
        {employees.map((employee) => (
          <tr key={employee.id}>
            <td>{employee.name}</td>
            <td>{employee.title}</td>
            <td>{employee.email}</td>
            <td>{employee.role}</td>
            <td>{employee.teams.join(', ')}</td>
            <td>{employee.updatedAt || employee.createdAt}</td>
            <td>
              <div className={st.row}>
                <SquareBtn className={st.manageButton} color="white" onClick={() => openEditModal(employee.id)}>
                  수정
                </SquareBtn>
                <SquareBtn className={st.manageButtonGrey} color="white" onClick={() => openWithdrawModal(employee.id)}>
                  퇴사처리
                </SquareBtn>
              </div>
            </td>
          </tr>
        ))}
        {employees.length < 14 &&
          Array.from({ length: 14 - employees.length }).map((_, index) => (
            <tr key={index}>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          ))}
      </tbody>
    </table>
  );
});

export default WorkingStaffTable;
