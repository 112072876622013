import formDataToJson from '@common/module/submit';
import { _alert } from '@model/dialogModel';
import { roleTypes, teamTypes } from '@pages/StaffList/constant';
import {
  useEmployeeInfoQuery,
  useEmployeeTeamMembersQuery,
  usePutEmployeeInfoMutation,
  useRolesAndTeamsQuery,
} from '@pages/StaffList/queries';
import { getEmployeeTeamTypes } from '@pages/StaffList/utils';
import AppVm from 'AppVm';
import { runInAction } from 'mobx';
import { useLocalObservable } from 'mobx-react';
import { useEffect, useMemo } from 'react';

/**
 *
 * @param { employeeId: number } param
 */
const useEditStaffInfo = ({ employeeId }) => {
  const { data: employee } = useEmployeeInfoQuery(employeeId);
  const { data: rolesAndTeams } = useRolesAndTeamsQuery();
  const { data: teamMembers } = useEmployeeTeamMembersQuery();

  const employeeInfoMutation = usePutEmployeeInfoMutation();

  const formMob = useLocalObservable(() => ({
    name: '',
    title: '',
    role: '',
    originalTeams: new Set(),
    selectedTeams: new Set(),
    담당자변경이_필요한_teamTypeSet: new Set(),
    brEmpId: '',
    onChange(key, e) {
      this[key] = e.target.value?.trim() ?? '';
    },
    getIsTeamUpdated() {
      return (
        this.originalTeams.size !== this.selectedTeams.size ||
        ![...this.selectedTeams].every((id) => this.originalTeams.has(id))
      );
    },
    initialize(employee) {
      runInAction(() => {
        this.name = employee?.name ?? '';
        this.title = employee?.title ?? '';
        this.role = employee?.role ?? '';

        const newTeams = employee?.teams?.map((team) => team.id) ?? [];

        this.selectedTeams = new Set(newTeams);
        this.originalTeams = new Set(newTeams);
      });
    },
  }));

  const employeeTeamTypes = useMemo(() => getEmployeeTeamTypes(employee), [employee]);

  const teamIdsByType = useMemo(() => {
    return rolesAndTeams?.teams?.reduce(
      (acc, team) => {
        acc[team.businessRegistrationYn ? teamTypes.사업자등록 : teamTypes.기장].push(team.id);

        return acc;
      },
      { [teamTypes.기장]: [], [teamTypes.사업자등록]: [] },
    );
  }, [rolesAndTeams]);

  const onSubmit = (e) => {
    e.preventDefault();

    const isUpdated =
      formMob.name !== employee?.name ||
      formMob.title !== employee?.title ||
      formMob.role !== employee?.role ||
      formMob.getIsTeamUpdated();

    if (!isUpdated) {
      _alert('수정사항이 없습니다.');

      return;
    }

    if (formDataToJson(e.target).error) {
      return;
    }

    const param = {
      id: employeeId,
      name: formMob.name,
      title: formMob.title,
      role: formMob.role,
      isTeamsUpdated: formMob.getIsTeamUpdated(),
      teams: [...formMob.selectedTeams],
    };

    if (param.isTeamsUpdated) {
      if (formMob.bkEmpId) {
        param.bkEmpId = formMob.bkEmpId;
      }
      if (formMob.brEmpId) {
        param.brEmpId = formMob.brEmpId;
      }
    }

    employeeInfoMutation.mutate(param, {
      onSuccess() {
        AppVm.popupClose();
        _alert('수정되었습니다.');
      },
    });
  };

  useEffect(() => {
    if (employee) {
      formMob.initialize(employee);
    } else {
      formMob.initialize();
    }
  }, [employee]);

  // 담당자 변경 선택이 필요한지 판단
  useEffect(() => {
    runInAction(() => {
      if (!teamIdsByType) {
        formMob.담당자변경이_필요한_teamTypeSet.clear();
        formMob.brEmpId = '';

        return;
      }

      const selectedTeamIds = [...formMob.selectedTeams];

      if (
        employeeTeamTypes.includes(teamTypes.사업자등록) &&
        formMob.role !== roleTypes.GENERAL_MANAGER &&
        !selectedTeamIds.some((teamId) => teamIdsByType[teamTypes.사업자등록].includes(teamId))
      ) {
        formMob.담당자변경이_필요한_teamTypeSet.add(teamTypes.사업자등록);
      } else {
        formMob.담당자변경이_필요한_teamTypeSet.delete(teamTypes.사업자등록);
        formMob.brEmpId = '';
      }
    });
  }, [employeeTeamTypes, teamIdsByType, formMob.selectedTeams.size, formMob.role]);

  return {
    email: employee?.email || '',
    roles: rolesAndTeams?.roles?.map((role) => ({ id: role.key, value: role.value })) ?? [],
    teams: rolesAndTeams?.teams ?? [],
    teamMembers: {
      businessRegistrationTeamEmployees:
        teamMembers?.businessRegistrationTeamEmployees?.filter((employee) => employee.id !== employeeId) || [],
    },
    updatedAt: employee?.updatedAt || employee?.createdAt || '',
    isSubmitting: employeeInfoMutation.isPending,
    formMob,
    onSubmit,
  };
};

export default useEditStaffInfo;
