import { get, patch, post } from '@common/module/httpRequest';

class TeamListService {
  async getTeams() {
    const res = await get(`/admin/teams`);

    return {
      teams:
        res.data.teams?.map((team) => ({
          id: Number(team.id),
          // 팀 명칭
          name: team.name || '',
          // 소속 직원
          affiliatedEmployees: team.affiliatedEmployees?.map((employee) => employee || '') || [],
          // 사업자등록 여부
          businessRegistrationYn: Boolean(team.businessRegistrationYn),
          // 업데이트 일시 YYYY-MM-DD HH:mm
          updatedAt: team.updatedAt || '',
        })) ?? [],
    };
  }
  async postTeam(param) {
    if (!param?.name) {
      throw new Error('Required field is empty.');
    }

    await post('/admin/teams', param);
  }
  async patchTeam(teamId, param) {
    if (!teamId || !param?.name) {
      throw new Error('Required field is empty.');
    }

    await patch(`/admin/teams/${teamId}`, {
      name: param.name,
    });
  }
}

export default new TeamListService();
