import './index.css';

import { pageRouter } from '@model/pageModel';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactDOM from 'react-dom/client';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

Sentry.init({
  dsn: 'https://435b09bd882b254c605cfe5188443f5d@o774769.ingest.sentry.io/4506301955571712',
  integrations: [
    new Sentry.Replay({
      networkDetailAllowUrls: [/^https:\/\/accounting\.admin\.api\.standby\.kr/],
      networkRequestHeaders: ['Authorization', 'Accept', 'User-Agent', 'Cache-Control'],
      networkResponseHeaders: ['Content-Type', 'Date'],
    }),
  ],
  environment: process.env.NODE_ENV,
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  maskAllText: false,
  maskAllInputs: false,
  blockAllMedia: false,
  ignoreErrors: ['AxiosError'],
});

/**
 * @note 페이지를 여러 탭에서 열어두면 msw가 정상 작동하지 않습니다.
 */
async function enableMocking() {
  // mocking 사용시 true 처리
  const isMockingOn = false;

  if (process.env.NODE_ENV !== 'development' && isMockingOn) {
    return;
  }

  const { worker } = await import('./mocks/browser');

  return worker.start();
}

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: 0,
      onError: console.error,
    },
    queries: {
      retry: 0,
      throwOnError: true,
      onError: console.error,
    },
  },
});

enableMocking().then(() => {
  root.render(
    <HistoryRouter history={pageRouter.history}>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </HistoryRouter>,
  );
});
