import { createQueryKeys } from '@lukemorales/query-key-factory';
import StaffListService from '@service/StaffListService';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { registrationStatusTypes } from '../constant';

export const queryKey = createQueryKeys('staff-list', {
  employees: (registrationStatusType) => [registrationStatusType],
  employeeInfo: (employeeId) => [employeeId],
  employeeTeamMembers: (employeeId) => [employeeId],
  rolesAndTeams: null,
});

/**
 * 직원 목록 조회
 */
export const useEmployeeListQuery = (registrationStatusType) => {
  return useQuery({
    ...queryKey.employees(registrationStatusType),
    queryFn: () => StaffListService.getEmployees(registrationStatusType),
    enabled: !!registrationStatusType,
  });
};

/**
 * 직원 정보 조회
 * @param {number} employeeId
 */
export const useEmployeeInfoQuery = (employeeId) => {
  return useQuery({
    ...queryKey.employeeInfo(employeeId),
    queryFn: () => StaffListService.getEmployeeInfo(employeeId),
    enabled: !!employeeId,
  });
};

/**
 * 직원 정보 수정
 */
export const usePutEmployeeInfoMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (param) => StaffListService.putEmployeeInfo(param.id, param),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: queryKey.employees(registrationStatusTypes.재직중).queryKey });
    },
  });
};

/**
 * 직원 가입 승인 처리
 */
export const usePostEmployeeApprovalMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (param) => StaffListService.postEmployeeApproval(param.id, param),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: queryKey.employees._def });
    },
  });
};

/**
 * 직원 퇴사 처리 및 가입승인 거절 처리
 * param으로 bkEmpId, brEmpId를 전달하지 않으면 단순 퇴사처리 혹은 가입승인 거절
 */
export const useDeleteEmployeeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (param) => StaffListService.deleteEmployee(param.id, param),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: queryKey.employees._def });
    },
  });
};

/**
 * 직원이 속한 팀의 회원 목록
 * @param {number | undefined} [employeeId] 주어지면 소속팀의 다른 회원 목록 조회, 안주어지면 팀 카테고리에 속한 모든 회원 목록 조회
 */
export const useEmployeeTeamMembersQuery = (employeeId) => {
  return useQuery({
    ...queryKey.employeeTeamMembers(employeeId),
    queryFn: () => StaffListService.getEmployeeTeamMembers(employeeId),
  });
};

/**
 * 권한 및 조직 목록 조회
 */
export const useRolesAndTeamsQuery = () => {
  return useQuery({
    ...queryKey.rolesAndTeams,
    queryFn: () => StaffListService.getRolesAndTeams(),
  });
};
