import { _delete, get, post, put } from '@common/module/httpRequest';
import { registrationStatusTypes } from '@pages/StaffList/constant';

class StaffListService {
  async getEmployees(registrationStatusType = registrationStatusTypes.재직중) {
    const res = await get(`/admin/employees?registrationStatusType=${registrationStatusType}`);

    return {
      employees: res.data.employees?.map((employee) => ({
        id: Number(employee.id),
        name: employee.name || '',
        title: employee.title || '',
        email: employee.email || '',
        role: employee.role || '',
        teams: employee.teams?.map((team) => team || '') ?? [],
        createdAt: employee.createdAt || '',
        updatedAt: employee.updatedAt || '',
      })),
    };
  }
  async getEmployeeInfo(employeeId) {
    if (!employeeId) {
      throw new Error('employeeId is required.');
    }

    const { data: employee } = await get(`/admin/employees/${employeeId}`);

    return {
      name: employee?.name || '',
      title: employee?.title || '',
      email: employee?.email || '',
      role: employee?.role || '',
      teams:
        employee?.teams?.map((team) => ({
          id: Number(team.id),
          value: team.value || '',
          businessRegistrationYn: Boolean(team.businessRegistrationYn),
        })) ?? [],
      createdAt: employee?.createdAt || '',
      updatedAt: employee?.updatedAt || '',
    };
  }
  /**
   * 직원 정보 수정
   * @param {number} employeeId
   * @param {*} param
   */
  async putEmployeeInfo(employeeId, param) {
    if (!employeeId || !param || !param.name || !param.role) {
      throw new Error('Required field is empty.');
    }

    const body = {
      name: param.name || '',
      title: param.title || '',
      role: param.role || '',
      isTeamsUpdated: false,
    };

    if (param.isTeamsUpdated && param.teams) {
      body.isTeamsUpdated = true;
      body.teams = param.teams.map(Number);

      /**
       * 변경될 기장 담당자 id
       * 기장팀 소속이 모두 해제됬을 경우, 담당자 변경을 위해 전달
       */
      if (param.bkEmpId) body.bkEmpId = param.bkEmpId;
      /**
       * 변경될 사업자등록 담당자 id
       * 사업자등록팀 소속이 모두 해제됬을 경우, 담당자 변경을 위해 전달
       */
      if (param.brEmpId) body.brEmpId = param.brEmpId;
    }

    await put(`/admin/employees/${employeeId}`, body);
  }
  /**
   * 가입승인 처리
   * @param {number} employeeId
   * @param {*} param
   */
  async postEmployeeApproval(employeeId, param) {
    if (!employeeId || !param || !param.name || !param.role) {
      throw new Error('Required field is empty.');
    }

    const body = {
      name: param.name || '',
      title: param.title || '',
      role: param.role || '',
      isTeamsUpdated: false,
    };

    if (param.isTeamsUpdated && param.teams) {
      body.isTeamsUpdated = true;
      body.teams = param.teams.map(Number);
    }

    await post(`/admin/employees/${employeeId}/approval`, body);
  }
  /**
   *
   * @param {string} employeeId
   * @param {*} param // 값이 없는 경우 단순 퇴사처리 혹은 가입승인 거절. 값이 주어지면 담당자 변경 처리도 함께
   */
  async deleteEmployee(employeeId, param) {
    if (!employeeId) {
      throw new Error('Required field is empty.');
    }

    const queryParams = [];

    // 기장 팀 직원 id
    if (param?.bkEmpId) {
      queryParams.push(`bkEmpId=${param.bkEmpId}`);
    }
    // 사업자등록 팀 직원 id
    if (param?.brEmpId) {
      queryParams.push(`brEmpId=${param.brEmpId}`);
    }

    await _delete(`/admin/employees/${employeeId}${queryParams.length > 0 ? `?${queryParams.join('&')}` : ''}`);
  }
  // 직원이 속한 팀의 회원 목록
  async getEmployeeTeamMembers(employeeId) {
    const res = await get(`/auth/employees/team${employeeId ? `?employeeId=${employeeId}` : ''}`);

    return {
      // 사업자등록 팀
      businessRegistrationTeamEmployees:
        res.data?.businessRegistrationTeamEmployees?.map((employee) => ({
          id: Number(employee.id),
          value: employee.value || '',
        })) ?? [],
      // 기장 팀
      bookkeepingTeamEmployees:
        res.data?.bookkeepingTeamEmployees?.map((employee) => ({
          id: Number(employee.id),
          value: employee.value || '',
        })) ?? [],
    };
  }
  async getRolesAndTeams() {
    const res = await get(`/auth/team`);

    return {
      roles:
        res.data?.roles.map((role) => ({
          key: role.key || '',
          value: role.value || '',
        })) ?? [],
      teams:
        res.data?.teams.map((team) => ({
          id: Number(team.id),
          value: team.value || '',
          businessRegistrationYn: Boolean(team.businessRegistrationYn),
        })) ?? [],
    };
  }
}

export default new StaffListService();
