import BusinessListModel from '@model/BusinessListModel';
import { pageRouter } from '@model/pageModel';
import BusinessListService from '@service/BusinessListService';
import { observable } from 'mobx';

class BusinessListVm {
  constructor() {
    this.state = observable({
      activeTab: null,
    });
  }
  load() {
    if (this.selectedUserId) {
      BusinessListModel.columns = [];
      BusinessListService.load();
    } else {
      this.loadGridFilter();
    }
  }
  get columns() {
    return BusinessListModel.columns;
  }
  get employeeList() {
    return BusinessListModel.employeeList;
  }
  get selectedUserId() {
    return BusinessListModel.selectedUserId;
  }
  get tabs() {
    return BusinessListModel.tabs;
  }
  onTab(customerGridTabType) {
    if (customerGridTabType) {
      pageRouter.replace(`/business/list?type=${customerGridTabType}`);
    } else {
      pageRouter.replace(`/business/list`);
    }
  }
  resetLoad() {
    BusinessListModel.columns = [];
    BusinessListModel.selectedUserId = null;
  }
  loadGridFilter() {
    BusinessListService.loadGridFilter();
  }
  changeSelectedUserId(e) {
    BusinessListModel.selectedUserId = e.target.value;
  }
  gridData() {
    return {
      getRows: async (params) => {
        const jsonRequest = params.request;

        jsonRequest.tab = this.state.activeTab;
        const selectedUserId = this.selectedUserId;
        const res = await BusinessListService.gridData(jsonRequest, selectedUserId);

        params.successCallback(res.rows.data, res.rows.lastRow);
      },
    };
  }
  onDragStopped(e) {
    const sendData = e.columnApi.getColumnState();

    BusinessListService.onDragStopped(sendData);
  }
  onChangeData(e) {
    const sendData = {
      type: e.type,
      pinned: e.pinned,
      visible: e.visible,
      colIds: e?.columns?.map((column) => column.colId),
      value: e.value,
      colId: e?.column?.colId,
      rowData: e.data,
    };
    const selectedUserId = this.selectedUserId;

    BusinessListService.onChangeData(sendData, selectedUserId);
  }
  onGridPageSizeChanged(e, gridRef) {
    const value = e.target.value;

    gridRef.current.api.paginationSetPageSize(Number(value));
  }
}

export default new BusinessListVm();
