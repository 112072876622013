import BusinessHomeModel from '@model/BusinessHomeModel';
import BusinessHomeService from '@service/BusinessHomeService';
import { addMonths, format, lastDayOfMonth, parseISO, subDays } from 'date-fns';
import { observable, runInAction, toJS } from 'mobx';

class BookKeepingServiceUnfreezeRequestModalVm {
  constructor() {
    this.data = observable({
      alimtalkToList: toJS(BusinessHomeModel.business.customer.alimtalkToList.BOOKKEEPING_SERVICE_PAUSE_REQUEST),
      pauseStartedAt: toJS(BusinessHomeModel.business.customer.bookkeepingService.pauseStartedAt),
      resumedAt: '',
      preliminaryReturnType: '',
    });
  }
  onChangeResumedAt(e) {
    let date = '';

    if (e) {
      const year = e.getFullYear();
      const month = ('0' + (e.getMonth() + 1)).slice(-2);
      const day = ('0' + e.getDate()).slice(-2);

      date = year + '-' + month + '-' + day;
    }

    runInAction(() => {
      this.data.resumedAt = date;
      this.data.preliminaryReturnType = '';
    });
  }
  get selectableResumeMonths() {
    return BusinessHomeModel.business.customer.bookkeepingService.selectableResumeMonths;
  }
  get selectableVatTypes() {
    return BusinessHomeModel.business.customer.bookkeepingService.selectableVatTypes;
  }
  get needPreliminaryReturnYn() {
    // date에서 month 추출
    const month = new Date(this.data.resumedAt).getMonth() + 1;

    // month와 일치하는 preliminaryReturnYn 값 찾기
    return this.selectableResumeMonths.find((item) => item.month === month)?.preliminaryReturnYn;
  }
  get resumedAtMinDate() {
    if (this.data.pauseStartedAt) {
      return subDays(new Date(this.data.pauseStartedAt), -1);
    }

    return '';
  }
  get resumedAtMaxDate() {
    if (this.data.pauseStartedAt) {
      // ISO 형식으로 날짜를 파싱
      const parsedDate = parseISO(this.data.pauseStartedAt);

      // 3개월 뒤 계산
      const threeMonthsLater = addMonths(parsedDate, 3);

      // 3개월 뒤의 마지막 날 계산
      const lastDay = lastDayOfMonth(threeMonthsLater);

      // 결과를 YYYY-MM-DD 형식으로 출력
      return format(lastDay, 'yyyy-MM-dd');
    }

    return '';
  }
  preliminaryReturnType(e) {
    this.data.preliminaryReturnType = e.target.value;
  }
  changeAlimtalkToList(e, index) {
    this.data.alimtalkToList[index].checkedYn = e.target.checked;
  }
  isSaveBtnActive(type) {
    if (!this.data.resumedAt) {
      return false;
    }
    if (this.needPreliminaryReturnYn === true && !this.data.preliminaryReturnType) {
      return false;
    }
    if (type === 'alimtalk') {
      return this.data.alimtalkToList.some((person) => {
        //알림톡 받으실분
        return person.checkedYn === true;
      });
    }

    return true;
  }
  request(businessId, type) {
    const param = {
      resumedAt: this.data.resumedAt,
      preliminaryReturnType: this.data.preliminaryReturnType || null,
    };

    if (type === 'alimtalk') {
      param.userIds = this.data.alimtalkToList
        .filter((person) => person.checkedYn)
        .map((checkedPerson) => checkedPerson.id);
    }

    BusinessHomeService.bookKeepingServiceUnfreeze(businessId, param, type);
  }
}

export default BookKeepingServiceUnfreezeRequestModalVm;
