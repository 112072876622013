import { addCommas } from '@common/module/replaceNumber';
import MessageHint from '@commonComponents/MessageHint/MessageHint';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import clsx from 'clsx';
import { useObserver } from 'mobx-react';
import queryString from 'query-string';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import st from './BookeepingFeeChangeModal.module.scss';
import BookKeepingFeeChangeModalVm from './BookKeepingFeeChangeModalVm';

function BookKeepingFeeChangeModal() {
  const [vm] = useState(() => new BookKeepingFeeChangeModalVm());

  const location = useLocation();
  const businessId = queryString.parse(location.search).businessId;

  return useObserver(() => (
    <div className={st.modalContent}>
      <ul>
        <li>
          <div className={st.title}>
            월 기장료
            <br />
            (부가세 제외)
          </div>
          <div>
            <InputText
              name=""
              value={addCommas(vm.data.bookkeepingServiceFee)}
              onChange={(e) => vm.bookkeepingServiceFee(e)}
              style={{ width: '298px', textAlign: 'right' }}
            />
            <span className={st.inputWon}>원</span>
          </div>
        </li>
        <li>
          <div className={st.title} style={{ top: '-11px' }}>
            계약서 새로작성
          </div>
          <div>
            <ul className={st.checkList}>
              <li>
                <CheckBox
                  checked={vm.data.needNewBookkeepingServiceContract}
                  onChange={(e) => vm.changeCheckbox(e, 'needNewBookkeepingServiceContract')}
                >
                  기장계약서
                </CheckBox>
              </li>
              <li>
                <CheckBox
                  checked={vm.data.needNewCMSContract}
                  onChange={(e) => vm.changeCheckbox(e, 'needNewCMSContract')}
                >
                  CMS 계약서
                </CheckBox>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div className={st.title} style={{ top: '-11px' }}>
            알림톡 받으실 분
          </div>
          <div>
            <ul className={st.checkList}>
              {vm.data.alimtalkToList.map((person, index) => (
                <li key={index}>
                  <CheckBox checked={person.checkedYn} onChange={(e) => vm.changeAlimtalkToList(e, index)}>
                    {person.name}님
                  </CheckBox>
                </li>
              ))}
            </ul>
          </div>
        </li>
      </ul>
      <div className={st.buttonArea}>
        <button
          className={clsx(st.saveBtn)}
          disabled={!vm.saveActive('save')}
          style={{ width: '100%' }}
          onClick={() => vm.request(businessId, 'save')}
        >
          <span>적용하기</span>
        </button>
        <button
          className={clsx(st.saveBtn, st.send)}
          disabled={!vm.saveActive('alimtalk')}
          style={{ width: '100%' }}
          onClick={() => vm.request(businessId, 'alimtalk')}
        >
          <span>적용 + 알림톡 발송</span>
        </button>
      </div>
      <MessageHint text="알림톡 메세지보기" style={{ marginTop: '8px' }}>
        <img src="/images/talkPreview기장료 변경.png" />
      </MessageHint>
    </div>
  ));
}

export default BookKeepingFeeChangeModal;
