import BusinessHomeModel from '@model/BusinessHomeModel';
import { _alert } from '@model/dialogModel';
import portalModel from '@model/portalModel';
import AlimtalkSendModal from '@pages/AlimtalkSendModal/AlimtalkSendModal';
import BusinessHomeService from '@service/BusinessHomeService';
import { observable, runInAction } from 'mobx';

import AvoidBookkeepingContractModal from './AvoidBookkeepingContractModal/AvoidBookkeepingContractModal';
import BookKeepingFeeChangeModal from './BookKeepingFeeChangeModal/BookKeepingFeeChangeModal';
import BookKeepingServicePauseRequestModal from './BookKeepingServicePauseRequestModal/BookKeepingServicePauseRequestModal';
import BookKeepingServiceUnfreezeRequestModal from './BookKeepingServiceUnfreezeRequestModal/BookKeepingServiceUnfreezeRequestModal';
import BusinessRegistrationModal from './BusinessRegistrationModal/BusinessRegistrationModal';
import FreeBookKeepingServiceExtensionModal from './FreeBookKeepingServiceExtensionModal/FreeBookKeepingServiceExtensionModal';
import StockholderRegisterRequestModal from './StockholderRegisterRequestModal/StockholderRegisterRequestModal';

class BusinessHomeVm {
  constructor() {
    this.state = observable({
      customerMoreViewOpened: false,
    });
  }
  load(businessId) {
    BusinessHomeService.load(businessId);
  }
  get business() {
    return BusinessHomeModel.business;
  }
  valueAddedTaxSelectedIndex(e, businessId) {
    this.business.progress.valueAddedTax.selectList.selectedIndex = e.target.value;

    const selectedPeriod = this.business.progress.valueAddedTax.selectList.periodList[e.target.value];

    BusinessHomeService.valueAddedTaxProgressSelect(businessId, selectedPeriod.year, selectedPeriod.value);
  }
  corporateTaxTaxSelectedIndex(e, businessId) {
    this.business.progress.corporateTax.selectList.selectedIndex = e.target.value;

    const selectedPeriod = this.business.progress.corporateTax.selectList.periodList[e.target.value];

    BusinessHomeService.corporateTaxProgressSelect(businessId, selectedPeriod.year);
  }
  bookKeepingServicePauseRequestModalOpen() {
    runInAction(() => {
      portalModel.title = '기장계약 정지하기';
      portalModel.content = <BookKeepingServicePauseRequestModal />;
    });
  }
  bookKeepingServiceUnfreezeRequestModalOpen() {
    runInAction(() => {
      portalModel.title = '기장계약 정지 해제하기';
      portalModel.content = <BookKeepingServiceUnfreezeRequestModal />;
    });
  }
  businessRegistrationModalOpen() {
    runInAction(() => {
      portalModel.title = '사업자등록증 변경';
      portalModel.content = <BusinessRegistrationModal />;
    });
  }
  stockholderRegisterRequestModalOpen() {
    runInAction(() => {
      portalModel.title = '최신 주주명부 요청';
      portalModel.content = <StockholderRegisterRequestModal />;
    });
  }
  avoidBookkeepingContractModalOpen() {
    runInAction(() => {
      portalModel.title = '기장계약 해지하기';
      portalModel.content = <AvoidBookkeepingContractModal />;
    });
  }
  freeBookKeepingServiceExtensionModalOpen() {
    runInAction(() => {
      portalModel.title = '무료기간 연장하기';
      portalModel.content = <FreeBookKeepingServiceExtensionModal />;
    });
  }
  bookKeepingFeeChangeModalOpen() {
    runInAction(() => {
      portalModel.title = '기장료 변경하기';
      portalModel.content = <BookKeepingFeeChangeModal />;
    });
  }
  cancelBookkeepingContract(businessId) {
    BusinessHomeService.cancelBookkeepingContract(businessId);
  }
  async fileDown(url) {
    if (!url) {
      return await _alert('등기부가 존재하지 않습니다.');
    }

    await BusinessHomeService.fileDownload(url);
  }
  openAlimtalkModal(businessId) {
    runInAction(() => {
      portalModel.title = '수동 알림톡 발송';
      portalModel.content = <AlimtalkSendModal businessId={businessId} />;
    });
  }
}

export default new BusinessHomeVm();
