import formDataToJson from '@common/module/submit';
import { _alert } from '@model/dialogModel';
import { roleTypeToKor } from '@pages/StaffList/constant';
import { teamTypes } from '@pages/StaffList/constant';
import { useDeleteEmployeeMutation, useEmployeeInfoQuery, useEmployeeTeamMembersQuery } from '@pages/StaffList/queries';
import AppVm from 'AppVm';
import { useLocalObservable } from 'mobx-react';

const useWithdrawStaffModal = ({ employeeId }) => {
  const { data: employee, isSuccess } = useEmployeeInfoQuery(employeeId);
  const { data: teamMembers } = useEmployeeTeamMembersQuery(employeeId);
  const deleteMutation = useDeleteEmployeeMutation();

  const formMob = useLocalObservable(() => ({
    변경될_기장_담당자_ID: '',
    변경될_사업자등록_담당자_ID: '',
    onSelect(type, e) {
      const selectedValue = e?.target?.value || '';

      if (type === teamTypes.기장) {
        this.변경될_기장_담당자_ID = selectedValue;
      } else if (type === teamTypes.사업자등록) {
        this.변경될_사업자등록_담당자_ID = selectedValue;
      }
    },
  }));

  const onSubmit = (e) => {
    e.preventDefault();

    if (formDataToJson(e.target).error) {
      return;
    }

    const param = { id: employeeId };

    if (formMob.변경될_기장_담당자_ID) {
      param['bkEmpId'] = formMob.변경될_기장_담당자_ID;
    }
    if (formMob.변경될_사업자등록_담당자_ID) {
      param['brEmpId'] = formMob.변경될_사업자등록_담당자_ID;
    }

    deleteMutation.mutate(param, {
      onSuccess() {
        AppVm.popupClose();
        _alert('퇴사처리 되었습니다.');
      },
    });
  };

  return {
    employee: {
      name: employee?.name || '',
      title: employee?.title || '',
      email: employee?.email || '',
      role: employee?.role ? roleTypeToKor[employee.role] : '',
      teams: employee?.teams?.map((team) => team.value || '') ?? [],
    },
    formMob,
    teamMembers: {
      bookkeepingTeamEmployees:
        teamMembers?.bookkeepingTeamEmployees?.filter((employee) => employee.id !== employeeId) || [],
      businessRegistrationTeamEmployees:
        teamMembers?.businessRegistrationTeamEmployees?.filter((employee) => employee.id !== employeeId) || [],
    },
    isSubmitting: !isSuccess || deleteMutation.isPending,
    onSubmit,
  };
};

export default useWithdrawStaffModal;
