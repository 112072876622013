import { teamTypes } from '@pages/StaffList/constant';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import { observer } from 'mobx-react';

import SelectRow from '../SelectRow';
import st from './style.module.scss';
import useWithdrawStaffModal from './useWithdrawStaffModal';

const WithdrawStaffModal = observer(({ employeeId }) => {
  const { employee, teamMembers, formMob, onSubmit, isSubmitting } = useWithdrawStaffModal({
    employeeId,
  });

  return (
    <div className={st.modalContent}>
      <div>
        <span className={st.name}>{`${employee.name} ${employee.title}`}</span>
        <span className={st.email}>{` (${employee.email})`}</span>
      </div>
      <div className={st.detail}>
        {`${employee.role}${employee.teams.length > 0 ? ' · ' : ''}${employee.teams.join(', ')}`}
      </div>
      <div className={st.divider} />
      <form onSubmit={onSubmit}>
        <div className={st.inputColumn}>
          <div className={st.detail}>* 퇴사처리 후 취소가 불가합니다.</div>
          {teamMembers.bookkeepingTeamEmployees.length > 0 && (
            <SelectRow
              type={teamTypes.기장}
              label="기장 담당자 변경"
              value={formMob.변경될_기장_담당자_ID}
              onChange={(e) => formMob.onSelect(teamTypes.기장, e)}
              options={teamMembers.bookkeepingTeamEmployees}
              requiredGuideMessage="담당자를 선택해 주세요."
            />
          )}
          {teamMembers.businessRegistrationTeamEmployees.length > 0 && (
            <SelectRow
              type={teamTypes.사업자등록}
              label="사업자등록 담당자 변경"
              value={formMob.변경될_사업자등록_담당자_ID}
              onChange={(e) => formMob.onSelect(teamTypes.사업자등록, e)}
              options={teamMembers.businessRegistrationTeamEmployees}
              requiredGuideMessage="담당자를 선택해 주세요."
            />
          )}
        </div>
        <SquareBtn className={st.withdrawButton} type="submit" disabled={isSubmitting}>
          퇴사처리하기
        </SquareBtn>
      </form>
    </div>
  );
});

export default WithdrawStaffModal;
