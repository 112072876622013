import formDataToJson from '@common/module/submit';
import { _alert } from '@model/dialogModel';
import { usePostTeamMutation, useTeamListQuery } from '@pages/TeamList/queries';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import AppVm from 'AppVm';
import { observer } from 'mobx-react';
import { useState } from 'react';

import st from './style.module.scss';

const CreateTeamModal = observer(() => {
  const { data } = useTeamListQuery();
  const createTeamMutation = usePostTeamMutation();

  const [name, setName] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();

    const newName = formDataToJson(e.target).data.name;

    if (!newName) {
      return;
    }

    createTeamMutation.mutate(
      { name: newName },
      {
        onSuccess() {
          AppVm.popupClose();
          _alert('등록되었습니다.');
        },
      },
    );
  };

  return (
    <div className={st.modalContent}>
      <form onSubmit={onSubmit}>
        <div className={st.inputColumn}>
          <div className={st.detail}>* 직원의 조직 분배는 [직원목록]에서 가능해요.</div>
          <div className={st.row}>
            <div className={st.category}>조직명</div>
            <InputText
              className={st.input}
              value={name}
              onChange={(e) => setName(e?.target?.value?.trim() || '')}
              name="name"
              errorText={
                <Error
                  name="name"
                  value={name}
                  errorCase={{
                    required: '조직명을 입력해 주세요.',
                    pattern: {
                      value: Boolean(data?.teams?.every((team) => team.name !== name) ?? false),
                      message: '동일한 조직명이 존재합니다.',
                    },
                  }}
                />
              }
            />
          </div>
        </div>
        <SquareBtn className={st.button} type="submit" disabled={createTeamMutation.isPending}>
          등록하기
        </SquareBtn>
      </form>
    </div>
  );
});

export default CreateTeamModal;
