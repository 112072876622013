export const registrationStatusTypes = {
  재직중: '재직중',
  가입승인대기: '가입승인대기',
};

export const roleTypes = {
  GENERAL_MANAGER: 'GENERAL_MANAGER',
  MANAGER: 'MANAGER',
};

export const roleTypeToKor = {
  [roleTypes.GENERAL_MANAGER]: '최고관리자',
  [roleTypes.MANAGER]: '일반회원',
};

export const teamTypes = {
  기장: '기장',
  사업자등록: '사업자등록',
};
