import BusinessHomeModel from '@model/BusinessHomeModel';
import BusinessHomeService from '@service/BusinessHomeService';
import { addDays, format } from 'date-fns';
import { observable, toJS } from 'mobx';

class FreeBookKeepingServiceExtensionModalVm {
  constructor() {
    this.data = observable({
      freeBookkeepingServiceEndAt: toJS(
        BusinessHomeModel.business.customer.bookkeepingService.freeBookkeepingServiceEndAt,
      ),
      alimtalkToList: toJS(
        BusinessHomeModel.business.customer.alimtalkToList.FREE_BOOKKEEPING_SERVICE_EXTENSION_REQUEST,
      ),
      newFreeBookkeepingServiceEndDate: '',
    });
  }
  newFreeBookkeepingServiceEndDate(e) {
    let date = null;

    if (e) {
      const year = e.getFullYear();
      const month = ('0' + (e.getMonth() + 1)).slice(-2);
      const day = ('0' + e.getDate()).slice(-2);

      date = year + '-' + month + '-' + day;
    }

    this.data.newFreeBookkeepingServiceEndDate = date;
  }
  changeAlimtalkToList(e, index) {
    this.data.alimtalkToList[index].checkedYn = e.target.checked;
  }
  isSaveBtnActive(type) {
    if (!this.data.newFreeBookkeepingServiceEndDate) {
      return false;
    }

    if (type === 'alimtalk') {
      return this.data.alimtalkToList.some((person) => {
        //알림톡 받으실분
        return person.checkedYn === true;
      });
    }

    return true;
  }
  request(businessId, type) {
    const param = {
      newFreeBookkeepingServiceEndDate: this.data.newFreeBookkeepingServiceEndDate,
    };

    if (type === 'alimtalk') {
      param.userIds = this.data.alimtalkToList
        .filter((person) => {
          return person.checkedYn === true;
        })
        .map((checkedPerson) => {
          return checkedPerson.id;
        });
    }

    BusinessHomeService.changeNewFreeBookkeepingServiceEndDate(businessId, param, type);
  }
  get newFreeBookkeepingServiceEndMinDate() {
    const freeBookkeepingServiceEndAt = this.data.freeBookkeepingServiceEndAt;
    // freeBookkeepingServiceEndAt에 하루를 더하기
    const freeBookkeepingServiceEndAtTomorrow = addDays(new Date(freeBookkeepingServiceEndAt), 1);

    let pauseAt;

    if (BusinessHomeModel.business?.customer?.bookkeepingService?.statusTypeHistories?.length > 0) {
      BusinessHomeModel.business.customer.bookkeepingService.statusTypeHistories.forEach((history) => {
        if (history?.pauseAt) {
          pauseAt = history?.pauseAt;
        }
      });
    }

    if (pauseAt) {
      const pauseAtTomorrow = addDays(new Date(pauseAt), 1);

      return freeBookkeepingServiceEndAtTomorrow > pauseAtTomorrow
        ? format(freeBookkeepingServiceEndAtTomorrow, 'yyyy-MM-dd')
        : format(pauseAtTomorrow, 'yyyy-MM-dd');
    }

    return format(freeBookkeepingServiceEndAtTomorrow, 'yyyy-MM-dd');
  }
}

export default FreeBookKeepingServiceExtensionModalVm;
