import MessageHint from '@commonComponents/MessageHint/MessageHint';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import Select from '@standby/common-ui/components/atoms/Input/Select/Select';
import DatePicker from '@standby/common-ui/components/molecules/DatePicker/DatePicker';
import clsx from 'clsx';
import { useObserver } from 'mobx-react';
import queryString from 'query-string';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import st from './BookKeepingServiceUnfreezeRequestModal.module.scss';
import BookKeepingServiceUnfreezeRequestModalVm from './BookKeepingServiceUnfreezeRequestModalVm';

function BookKeepingServiceUnfreezeRequestModal() {
  const [vm] = useState(() => new BookKeepingServiceUnfreezeRequestModalVm());

  const location = useLocation();
  const businessId = queryString.parse(location.search).businessId;

  return useObserver(() => (
    <div className={st.modalContent}>
      <ul>
        <li>
          <div className={st.title}>정지 해제일</div>
          <div className={st.datepicker}>
            <DatePicker
              name=""
              selected={vm.data.resumedAt}
              onChange={(e) => vm.onChangeResumedAt(e)}
              minDate={vm.resumedAtMinDate}
              maxDate={vm.resumedAtMaxDate}
            />
          </div>
        </li>
        {vm.needPreliminaryReturnYn === true && (
          <li>
            <div className={st.title}>부가세 예정신고</div>
            <div>
              <Select
                name=""
                value={vm.data.preliminaryReturnType}
                onChange={(e) => vm.preliminaryReturnType(e)}
                style={{ width: '298px' }}
              >
                <option value=""> - </option>
                {vm.selectableVatTypes.map((selectableVatType, index) => (
                  <option key={index} value={selectableVatType.value}>
                    {selectableVatType.name}
                  </option>
                ))}
              </Select>
            </div>
          </li>
        )}
        <li>
          <div className={st.title} style={{ top: '-4px' }}>
            알림톡 받으실 분
          </div>
          <div>
            <ul className={st.checkList}>
              {vm.data.alimtalkToList.map((person, index) => (
                <li key={index}>
                  <CheckBox checked={person.checkedYn} onChange={(e) => vm.changeAlimtalkToList(e, index)}>
                    {person.name}님
                  </CheckBox>
                </li>
              ))}
            </ul>
          </div>
        </li>
      </ul>
      <div className={st.buttonArea}>
        <span>
          <button
            className={st.saveBtn}
            disabled={!vm.isSaveBtnActive('save')}
            style={{ width: '100%' }}
            onClick={() => vm.request(businessId, 'save')}
          >
            <span>적용하기</span>
          </button>
        </span>
        <span>
          <button
            className={clsx(st.saveBtn, st.send)}
            disabled={!vm.isSaveBtnActive('alimtalk')}
            style={{ width: '100%' }}
            onClick={() => vm.request(businessId, 'alimtalk')}
          >
            <span>적용 + 알림톡 발송</span>
          </button>
          <MessageHint text="알림톡 메세지보기" style={{ marginTop: '8px' }}>
            <img src="/images/talkPreview_기장계약_일시정지_해제.png" />
          </MessageHint>
        </span>
      </div>
    </div>
  ));
}

export default BookKeepingServiceUnfreezeRequestModal;
