import { downloadFileGet, get, post } from '@common/module/httpRequest';
import { objectToFormData } from '@common/module/submit';
import BusinessHomeModel from '@model/BusinessHomeModel';
import { _alert } from '@model/dialogModel';

class BusinessHomeService {
  async load(businessId) {
    const res = await get(`/business/${businessId}/home`);

    BusinessHomeModel.setBusiness(res.data);
  }
  async valueAddedTaxProgressSelect(businessId, year, period) {
    const res = await get(`/business/${businessId}/home/valueAddedTax?year=${year}&period=${period}`);

    BusinessHomeModel.setValueAddedTaxProgress(res.data);
  }
  async corporateTaxProgressSelect(businessId, year) {
    const res = await get(`/business/${businessId}/home/corporateTax?year=${year}`);

    BusinessHomeModel.setCorporateTaxProgress(res.data);
  }
  async saveBusinessRegistration(businessId, param) {
    const formData = new FormData();

    objectToFormData(formData, param);
    await post(`/business/${businessId}/home/businessRegistration`, formData);
    await _alert('저장되었습니다.');
    window.location.reload();
  }
  async alimtalkBusinessRegistration(businessId, param) {
    const formData = new FormData();

    objectToFormData(formData, param);
    await post(`/business/${businessId}/home/businessRegistration/alimtalk`, formData);
    await _alert(
      '알림톡 발송을 시작합니다.',
      '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)',
    );
    window.location.reload();
  }
  async alimtalkStockholderList(businessId, param) {
    await post(`/business/${businessId}/home/shareholderList`, param);
    await _alert('주주명부 요청이 접수되었습니다.', '1~3분 소요됩니다. 잠시만 기다려주세요.');
    window.location.reload();
  }
  async cancelBookkeepingContract(businessId) {
    await post(`/business/${businessId}/home/bookkeepingContract/avoidance/cancel`);
    await _alert('해지가 취소되었습니다.');
    window.location.reload();
  }
  async avoidanceBookkeepingContract(businessId, param) {
    await post(`/business/${businessId}/home/bookkeepingContract/avoidance`, param);
    await _alert(`해지 예정 시간은 ${param.contractEndDate} 24:00입니다. 그 전까지는 해지 취소가 가능합니다.`);
    window.location.reload();
  }
  async changeBookkeepingServiceFee(businessId, param, type) {
    if (type === 'alimtalk') {
      await post(`/business/${businessId}/home/bookkeepingServiceFee/change/alimtalk`, param);
      await _alert(
        '알림톡 발송을 시작합니다.',
        '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)',
      );
    } else {
      await post(`/business/${businessId}/home/bookkeepingServiceFee/change`, param);
      await _alert('적용되었습니다.');
    }

    window.location.reload();
  }

  async fileDownload(url) {
    await downloadFileGet(url);
  }
  async changeNewFreeBookkeepingServiceEndDate(businessId, param, type) {
    if (type === 'alimtalk') {
      await post(`/business/${businessId}/home/freeBookkeepingService/extend/alimtalk`, param);
      await _alert(
        '알림톡 발송을 시작합니다.',
        '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)',
      );
    } else {
      await post(`/business/${businessId}/home/freeBookkeepingService/extend`, param);
      await _alert('적용되었습니다.');
    }

    window.location.reload();
  }
  async bookKeepingServicePause(businessId, param, type) {
    if (type === 'alimtalk') {
      await post(`/business/${businessId}/home/bookkeepingService/pause/alimtalk`, param);
      await _alert(
        '알림톡 발송을 시작합니다.',
        '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)',
      );
    } else {
      await post(`/business/${businessId}/home/bookkeepingService/pause`, param);
      await _alert('적용되었습니다.');
    }

    window.location.reload();
  }
  async bookKeepingServiceUnfreeze(businessId, param, type) {
    if (type === 'alimtalk') {
      await post(`/business/${businessId}/home/bookkeepingService/resume/alimtalk`, param);
      await _alert(
        '알림톡 발송을 시작합니다.',
        '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)',
      );
    } else {
      await post(`/business/${businessId}/home/bookkeepingService/resume`, param);
      await _alert('적용되었습니다.');
    }

    window.location.reload();
  }
}

export default new BusinessHomeService();
