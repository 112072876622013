import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import gridLocale from '@common/module/gridLocale.ko.js';
import PageNav from '@commonComponents/PageNav/PageNav';
import ArrayCellRenderer from '@pages/GridCommon/ArrayCellRenderer/ArrayCellRenderer';
import BusinessNameTimeline from '@pages/GridCommon/BusinessNameTimeline/BusinessNameTimeline';
import RowNumberRenderer from '@pages/GridCommon/RowNumberRenderer/RowNumberRenderer';
import { AgGridReact } from 'ag-grid-react';
import clsx from 'clsx';
import { observer, useObserver } from 'mobx-react';
import queryString from 'query-string';
import { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import st from './DelegationRegistrationList.module.scss';
import vm from './DelegationRegistrationListVm';
import DelegationRegistrationProcessing from './DelegationRegistrationProcessing/DelegationRegistrationProcessing';

function DelegationRegistrationList() {
  const gridRef = useRef();
  const location = useLocation();
  const activeTab = queryString.parse(location.search).type;

  useEffect(() => {
    vm.load();
  }, []);

  useEffect(() => {
    vm.state.activeTab = activeTab || null;
  }, [activeTab]);

  return useObserver(() => (
    <>
      <PageNav nav={['거래처', '스탠바이 이용등록']} />
      <div className={st.gridContent}>
        <button className={st.openProcess} type="button" onClick={() => vm.openWindow()}>
          이용등록
        </button>
        <button className={st.exportExcel} type="button" onClick={() => gridRef.current.api.exportDataAsExcel()}>
          엑셀로 내보내기
        </button>
        <ul className={st.tab}>
          {vm.tabs.map((tab, index) => (
            <li
              key={index}
              className={tab.delegationRegistrationStatusType === vm.state.activeTab ? st.active : null}
              onClick={() => vm.onTab(tab.delegationRegistrationStatusType)}
            >
              {tab.name}({tab.count})
            </li>
          ))}
        </ul>
        {vm.columns.length !== 0 && (
          <>
            <Grid gridRef={gridRef} activeTab={vm.state.activeTab} key={activeTab} />
            <div className={st.gridPageSize}>
              <span className={st.title}>Page Size</span>
              <select
                onChange={(e) => vm.onGridPageSizeChanged(e, gridRef)}
                defaultValue={'30'}
                className={st.selectedSmall}
              >
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </>
        )}
      </div>
    </>
  ));
}

const Grid = observer(function Grid(props) {
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      floatingFilter: true,
      resizable: true,
    }),
    [],
  );
  const components = useMemo(
    () => ({
      BusinessNameTimelineRenderer: BusinessNameTimeline,
      DelegationRegistrationBtnRenderer: DelegationRegistrationProcessing,
      ArrayCellRenderer: ArrayCellRenderer,
      RowNumberRenderer: RowNumberRenderer,
    }),
    [],
  );

  return (
    <div className={clsx(st.grid, 'ag-theme-alpine')}>
      <AgGridReact
        pagination={true}
        paginationPageSize={30}
        cacheBlockSize={30}
        ref={props.gridRef}
        localeText={gridLocale}
        components={components}
        columnDefs={vm.columns}
        defaultColDef={defaultColDef}
        rowModelType={'serverSide'}
        serverSideDatasource={vm.gridData()}
        onCellEditingStopped={vm.onChangeData}
        onDragStopped={vm.onDragStopped}
        onColumnPinned={vm.onChangeData}
        onColumnVisible={vm.onChangeData}
        suppressBrowserResizeObserver={true}
        suppressDragLeaveHidesColumns={true}
      />
    </div>
  );
});

export default DelegationRegistrationList;
