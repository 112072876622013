import { createQueryKeys } from '@lukemorales/query-key-factory';
import TeamListService from '@service/TeamListService';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const queryKey = createQueryKeys('team-list', {
  teams: null,
});

/**
 * 조직 목록 조회
 */
export const useTeamListQuery = () => {
  return useQuery({
    ...queryKey.teams,
    queryFn: () => TeamListService.getTeams(),
  });
};

/**
 * 조직 등록
 */
export const usePostTeamMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (param) => TeamListService.postTeam(param),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: queryKey.teams.queryKey });
    },
  });
};

/**
 * 조직 이름 변경
 */
export const usePatchTeamMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (param) => TeamListService.patchTeam(param.id, param),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: queryKey.teams.queryKey });
    },
  });
};
