import formDataToJson from '@common/module/submit';
import { _alert } from '@model/dialogModel';
import { useEmployeeInfoQuery, usePostEmployeeApprovalMutation, useRolesAndTeamsQuery } from '@pages/StaffList/queries';
import AppVm from 'AppVm';
import { runInAction } from 'mobx';
import { useLocalObservable } from 'mobx-react';
import { useEffect } from 'react';

/**
 *
 * @param {{ employeeId: number }} param
 */
const useEditStaffInfo = ({ employeeId }) => {
  const { data: employee } = useEmployeeInfoQuery(employeeId);
  const { data: rolesAndTeams } = useRolesAndTeamsQuery();
  const approveEmployeeMutation = usePostEmployeeApprovalMutation();

  const formMob = useLocalObservable(() => ({
    name: '',
    title: '',
    role: '',
    originalTeams: new Set(),
    selectedTeams: new Set(),
    onChange(key, e) {
      this[key] = e.target.value?.trim() ?? '';
    },
    getIsTeamUpdated() {
      return (
        this.originalTeams.size !== this.selectedTeams.size ||
        ![...this.selectedTeams].every((id) => this.originalTeams.has(id))
      );
    },
    initialize(employee) {
      runInAction(() => {
        this.name = employee?.name ?? '';
        this.title = employee?.title ?? '';
        this.role = employee?.role ?? '';

        const newTeams = employee?.teams?.map((team) => team.id) ?? [];

        this.selectedTeams = new Set(newTeams);
        this.originalTeams = new Set(newTeams);
      });
    },
  }));

  const onSubmit = (e) => {
    e.preventDefault();

    if (formDataToJson(e.target).error) {
      return;
    }

    const param = {
      id: employeeId,
      name: formMob.name,
      title: formMob.title,
      role: formMob.role,
      isTeamsUpdated: formMob.getIsTeamUpdated(),
      teams: [...formMob.selectedTeams],
    };

    approveEmployeeMutation.mutate(param, {
      onSuccess() {
        AppVm.popupClose();
        void _alert('가입 승인 처리 되었습니다.');
      },
    });
  };

  useEffect(() => {
    if (employee) {
      formMob.initialize(employee);
    } else {
      formMob.initialize();
    }
  }, [employee]);

  return {
    email: employee?.email || '',
    roles: rolesAndTeams?.roles?.map((role) => ({ id: role.key, value: role.value })) ?? [],
    teams: rolesAndTeams?.teams ?? [],
    createdAt: employee?.createdAt || '',
    isSubmitting: approveEmployeeMutation.isPending,
    formMob,
    onSubmit,
  };
};

export default useEditStaffInfo;
