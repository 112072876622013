import formDataToJson from '@common/module/submit';
import PageNav from '@commonComponents/PageNav/PageNav';
import { _alert } from '@model/dialogModel';
import portalModel from '@model/portalModel';
import ModalEditView from '@pages/common/ModalEdit/component/ModalEditView';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';

import CreateTeamModal from './components/CreateTeamModal';
import { usePatchTeamMutation, useTeamListQuery } from './queries';
import st from './style.module.scss';

const TeamList = observer(() => {
  const { data } = useTeamListQuery();
  const teamNameMutation = usePatchTeamMutation();

  const openAddGroupModal = () => {
    runInAction(() => {
      portalModel.title = '조직등록';
      portalModel.content = <CreateTeamModal />;
    });
  };

  const updateTeamNameHandler = (e, teamId) => {
    e.preventDefault();

    const name = formDataToJson(e.target).data.value;

    if (!name || teamNameMutation.isPending) {
      return;
    }

    teamNameMutation.mutate(
      { id: teamId, name },
      {
        onSuccess() {
          _alert('수정되었습니다.');
        },
      },
    );
  };

  const teams = data?.teams || [];

  return (
    <>
      <div className={st.header}>
        <PageNav nav={['권한관리', '조직목록']} />
        <SquareBtn onClick={() => openAddGroupModal()}>등록하기</SquareBtn>
      </div>
      <table className={st.staffList}>
        <colgroup>
          <col width="15%" />
          <col />
          <col width="10%" />
          <col width="14%" />
        </colgroup>
        <thead>
          <tr>
            <th>조직명</th>
            <th>소속 직원</th>
            <th>사업자등록 담당</th>
            <th>업데이트 일시</th>
          </tr>
        </thead>
        <tbody>
          {teams.map((team) => (
            <tr key={team.id}>
              <td>
                {team.name}
                <ModalEditView
                  inputType="text"
                  value={team.name}
                  placement="right"
                  onSubmit={(e) => updateTeamNameHandler(e, team.id)}
                />
              </td>
              <td>{team.affiliatedEmployees.join(', ')}</td>
              <td>{team.businessRegistrationYn ? 'O' : 'X'}</td>
              <td>{team.updatedAt}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
});

export default TeamList;
