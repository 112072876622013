import { roleTypes, teamTypes } from './constant';

export const getEmployeeTeamTypes = (employee) => {
  if (!employee) {
    return [];
  }

  // 모든 직원은 기본적으로 기장 업무를 수행할 권한이 있음
  const types = [teamTypes.기장];

  if (
    employee.teams.some((team) => team.businessRegistrationYn === true) ||
    employee.role === roleTypes.GENERAL_MANAGER
  ) {
    types.push(teamTypes.사업자등록);
  }

  return types;
};
