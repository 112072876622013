import { teamTypes } from '@pages/StaffList/constant';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Radio from '@standby/common-ui/components/atoms/Input/Radio/Radio';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import clsx from 'clsx';
import { observer } from 'mobx-react';

import SelectRow from '../SelectRow';
import st from './style.module.scss';
import useEditStaffInfo from './useEditStaffInfo';

const EditStaffModal = observer(({ employeeId }) => {
  const { email, roles, teams, teamMembers, updatedAt, formMob, isSubmitting, onSubmit } = useEditStaffInfo({
    employeeId,
  });

  return (
    <div className={st.modalContent}>
      <div className={st.name}>{email}</div>
      <div className={st.updatedAt}>{`업데이트 일시 : ${updatedAt}`}</div>
      <div className={st.divider} />
      <form onSubmit={onSubmit}>
        <div className={st.inputColumn}>
          <div className={st.row}>
            <div className={st.category}>이름</div>
            <InputText
              className={st.input}
              value={formMob.name}
              onChange={(e) => formMob.onChange('name', e)}
              name="name"
              errorText={
                <Error
                  name="name"
                  value={formMob.name}
                  errorCase={{
                    required: '이름을 입력해 주세요.',
                  }}
                />
              }
            />
          </div>
          <div className={st.row}>
            <div className={st.category}>직위</div>
            <InputText className={st.input} value={formMob.title} onChange={(e) => formMob.onChange('title', e)} />
          </div>
          <div className={st.row}>
            <div className={st.category}>최고관리자여부</div>
            <div className={st.radioGroup}>
              {roles.map((role) => (
                <Radio
                  key={role.id}
                  value={role.id}
                  checked={formMob.role === role.id}
                  onClick={(e) => formMob.onChange('role', e)}
                >
                  {role.value}
                </Radio>
              ))}
            </div>
          </div>
          <div className={st.row}>
            <div className={clsx(st.category, st.fixed)}>조직</div>
            <div className={st.checkBoxGroup}>
              {teams.map((team) => (
                <CheckBox
                  key={team.id}
                  value={team.id}
                  checked={formMob.selectedTeams.has(team.id)}
                  onChange={(e) =>
                    e?.target?.checked ? formMob.selectedTeams.add(team.id) : formMob.selectedTeams.delete(team.id)
                  }
                >
                  {team.value}
                </CheckBox>
              ))}
            </div>
          </div>
          {formMob.담당자변경이_필요한_teamTypeSet.has(teamTypes.사업자등록) && (
            <SelectRow
              type="brEmpId"
              label="사업자등록 담당자 변경"
              value={formMob.brEmpId}
              onChange={(e) => formMob.onChange('brEmpId', e)}
              options={teamMembers.businessRegistrationTeamEmployees}
              requiredGuideMessage="담당자를 선택해 주세요."
            />
          )}
        </div>
        <SquareBtn className={st.saveButton} type="submit" disabled={isSubmitting}>
          저장하기
        </SquareBtn>
      </form>
    </div>
  );
});

export default EditStaffModal;
