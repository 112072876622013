import { _alert } from '@model/dialogModel';
import portalModel from '@model/portalModel';
import { useDeleteEmployeeMutation } from '@pages/StaffList/queries';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import AppVm from 'AppVm';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';

import st from '../../style.module.scss';
import ApproveStaffModal from '../ApproveStaffModal';

const PendingApprovalStaffTable = observer(({ employees }) => {
  const rejectApprovalMutation = useDeleteEmployeeMutation();

  const openApprovalModal = (employeeId) => {
    runInAction(() => {
      portalModel.title = '가입 승인';
      portalModel.content = <ApproveStaffModal employeeId={employeeId} />;
    });
  };

  const rejectApproval = (employeeId) => {
    rejectApprovalMutation.mutate(
      { id: employeeId },
      {
        onSuccess() {
          _alert('가입 승인 거절 처리되었습니다.').then(() => AppVm.popupClose());
        },
      },
    );
  };

  return (
    <table className={st.staffList}>
      <colgroup>
        <col width="15%" />
        <col width="30%" />
        <col />
        <col width="15%" />
      </colgroup>
      <thead>
        <tr>
          <th>이름</th>
          <th>이메일</th>
          <th>가입승인요청 일시</th>
          <th>관리</th>
        </tr>
      </thead>
      <tbody>
        {employees.map((employee) => (
          <tr key={employee.id}>
            <td>{employee.name}</td>
            <td>{employee.email}</td>
            <td>{employee.createdAt}</td>
            <td>
              <div className={st.row}>
                <SquareBtn className={st.manageButton} color="white" onClick={() => openApprovalModal(employee.id)}>
                  승인
                </SquareBtn>
                <SquareBtn className={st.manageButtonGrey} color="white" onClick={() => rejectApproval(employee.id)}>
                  거절
                </SquareBtn>
              </div>
            </td>
          </tr>
        ))}
        {employees.length < 14 &&
          Array.from({ length: 14 - employees.length }).map((_, index) => (
            <tr key={index}>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          ))}
      </tbody>
    </table>
  );
});

export default PendingApprovalStaffTable;
